import { Card, Typography, CardMedia, CardContent, CardActions, Button } from "@mui/material";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { urlImage } from '../utils/common';
import React from "react";

function NewItem(newsItem: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    
    function handleClickToNews(news_slug: string = '1') {
      navigate(`/news/${news_slug}`);
    }

    return (
          <Card
            onClick={() => handleClickToNews(newsItem.newsItem.news_slug)}
            sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          >
            <CardMedia
              component="div"
              sx={{
                // 16:9
                pt: '56.25%',
              }}
              image={urlImage(newsItem.newsItem.img)}
            />
            <CardContent sx={{ flexGrow: 1, padding: '4px' }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                color={'primary'}
                >
                {newsItem.newsItem[`title_${i18n.language}`]}
              </Typography>
              <Typography variant="subtitle2" noWrap={true}>
                {newsItem.newsItem[`summary_${i18n.language}`]}
              </Typography>
            </CardContent>
            <CardActions sx={{alignSelf: 'center'}}>
              <Button variant="contained" size="small">{t('detail')}</Button>
            </CardActions>
          </Card>
    );
}
export default NewItem;