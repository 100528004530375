import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import LayoutPage from '../../components/layoutPage';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import BreadcrumbsPath from '../../components/Breadcrumbs';

function SinglePage() {
    const { i18n } = useTranslation();
    const params = useParams();

    const [page, setPage] = useState<any>()

    useEffect(() => {
        // check if input params is product category
        let pageSlug = ''
        if (params.hasOwnProperty('pageSlug') && params.pageSlug)
            pageSlug = params.pageSlug
        const getData = (domain: any) => {
            axios.get(
                `${domain}/page/${pageSlug}`,
                {headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                  }})
            .then(res => {
                setPage(res.data)
            })
        }

        try {
            getData(process.env.REACT_APP_API_DOMAIN)
        } catch (error) {
            getData(process.env.REACT_APP_API_DOMAIN)
        }
        
    }, [params])

    return (
        <LayoutPage>
            <BreadcrumbsPath pageName={page && page[`title_${i18n.language}`]} />
            <Typography variant='h5'>
                {page && page[`title_${i18n.language}`]}
            </Typography>
            <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: page && page[`description_${i18n.language}`] }}></div>
        </LayoutPage>
    );
}
export default SinglePage