import { Container, Divider, Grid, Typography, Paper, MenuList, MenuItem, Box } from "@mui/material";
import React from "react";
import { useTranslation } from 'react-i18next';
import FunicoLogo from '../static/Funico_logo_top.png';
import { useNavigate, Link } from "react-router-dom";

function Footer(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const footerMenu = ['introduce', 'payment_instructions', 'shipping_method', 'security_information', 'account'];
    
    return (
        <Container maxWidth={'xl'}>
            <Grid container>
                <Grid item md={4} xs={12}>
                    <Typography variant="h5">{t('about_us')}</Typography>
                    <Divider />
                    <Typography variant="h6">
                        {props.company && props.company[`name_${i18n.language}`]}
                    </Typography>
                    <Typography sx={{ textAlign: 'start' }}>
                        MST: {props.company && props.company[`tax_${i18n.language}`]}<br />
                        {t('store')}: {props.company && props.company[`addrs_${i18n.language}`]}<br />
                        Email: {props.company && props.company[`email_${i18n.language}`]}<br />
                        Hotline:{props.company && props.company[`phone_${i18n.language}`]}<br />
                    </Typography>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography variant="h5">{t('information')}</Typography>
                    <Divider />
                    <MenuList>
                        {footerMenu.map((page) => {
                           return (
                                <MenuItem>
                                    <Link style={{color: 'white', textDecoration: 'initial'}} to={`/${page}`}>
                                        {t(page)}
                                    </Link>
                                </MenuItem>
                            )
                        })}
                    </MenuList>
                </Grid>
                <Grid item md={4} xs={12}>
                    <Typography variant="h5">Fanpage</Typography>
                    <Divider />
                    <Box sx={{p: 4}}>
                        <img style={{height: '100%', width: '100%', cursor: 'pointer'}} src={FunicoLogo} onClick={() => navigate('/')}/>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                    <Typography>Copyright 2023 © Funicovn</Typography>
                </Grid>
            </Grid>
        </Container>
    );
}
export default Footer;