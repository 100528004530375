import {
    Box,
    Divider,
    Grid,
    Tabs,
    Tab,
    Typography
} from "@mui/material";
import ProductItem from '../../components/productItem';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from "react";
import axios from "axios";


function ProductSlideshow (productCat: any) {
    const { t, i18n } = useTranslation();
    const [products, setProducts] = useState([]);
    const [value, setValue] = React.useState(0);
    const location = useLocation();

    const [productCategory, setProductCategory] = useState<any>({});

    useEffect(() => {
        const getData = (domain: any) => {
            let url = `${domain}/`;
            const inputParams: any =  {limit: location.pathname === '/' ? 6 : 4}

            if (productCat.productCat?.exclude_id) {
                inputParams.excludeId = productCat.productCat?.exclude_id;
            }

            if (productCat.productCat?.pro_cat_slug) {
                url += `productCat/${productCat.productCat?.pro_cat_slug}`;
                axios.get(
                    url,
                    {
                        params: inputParams,
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                          }})
                .then(res => {
                    setProducts(res.data)
                })
                setProductCategory(productCat.productCat);
            } else {
                axios.get(
                    `${url}productCatItem/${productCat.productCat?.category_id}`,
                    {headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                      }})
                .then(res => {
                    setProductCategory(res.data);
                    url += `productCat/${res.data.pro_cat_slug}`;
                    axios.get(
                        url,
                        {
                            params: inputParams,
                            headers: {
                                "Content-Type": "application/x-www-form-urlencoded"
                              }})
                    .then(res => {
                        setProducts(res.data)
                    })  
                })
            }
        }

        getData(process.env.REACT_APP_API_DOMAIN)
        
    }, [])

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 1 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    return (
        <Box sx={{mb: 4}}>
            <Tabs centered value={value} onChange={() => {}} aria-label={productCategory?.id && productCategory[`name_${i18n.language}`]}>
                <Tab label={<Typography sx={{fontWeight: 500, textWrap: 'nowrap'}}>
                    {productCategory?.id && productCategory[`name_${i18n.language}`]}
                </Typography>}/>
            </Tabs>
            <Box width={'100%'}>
                <Box sx={{float: 'right'}}>
                    <Link to={`/category_product/${productCategory.pro_cat_slug}`}>
                        {t('view_all')}
                    </Link>
                </Box>
                <Divider />
            </Box>
            <TabPanel value={value} index={0} p={0}>
                <Grid container spacing={1}>
                    {products.length > 0 && products.map(product => (
                        <Grid item md={location.pathname === '/' ? 2 : 3} sm={location.pathname === '/' ? 4 : 3} xs={6}>
                            <ProductItem props={{'product': product}} />
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
        </Box>
    );
}

export default ProductSlideshow