import * as React from 'react';

import {
  Button,
  Avatar,
  Checkbox,
  Link,
  TextField,
  Grid,
  Box,
  Alert,
  CssBaseline,
  FormControlLabel,
  Typography,
  Container,
  CircularProgress
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import axios from "axios";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function SignIn(props: any) {
  const { t, i18n } = useTranslation();

  const [msgError, setMsgError] = React.useState<string>('');
  const [saving, setSaving] = React.useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSaving(true);
    const data = new FormData(event.currentTarget);
    setMsgError("")
    setTimeout(() => {
      axios.post(`${process.env.REACT_APP_API_DOMAIN}/login`, {username: data.get('email'), password: data.get('password')})
        .then(res => {
            localStorage.setItem('user_token', res.data.token)
            if (!!res.data.user)
              localStorage.setItem('user_info', JSON.stringify(res.data.user))
            props.setReload()
        })
        .catch((error) => {
          setMsgError(error.response.data)
        })
        .finally(() => setSaving(false))
    }, 500);
  };
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('sign_in')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="User name or email address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
              />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            {msgError && <Alert severity="error">Bạn đã nhập mật khẩu hoặc tên người dùng không chính xác. Vui lòng kiểm tra và thử lại.</Alert>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={saving}
              sx={{ mt: 3, mb: 2 }}
            >
              {t('sign_in')}
                {saving && (
                    <CircularProgress
                        size={30}
                        sx={{
                            m: 2,
                            position: 'absolute',
                        }}
                    />
                )}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  {t('forgot_password')}
                </Link>
              </Grid>
              <Grid item>
                <Link variant="body2"
                  onClick={
                    () => {
                      navigate(`/account/signup`)
                    }}
                  >
                  {t('dont_have_account')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}