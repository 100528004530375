import {
    Grid,
    CardMedia,
    Divider,
    Hidden,
    CircularProgress,
    Alert
} from "@mui/material"
import axios from "axios";
import LayoutPage from '../../components/layoutPage';
import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SidebarsProduct from "../../components/sidebarsProduct";
import NewsRelated from "./NewsRelated";
import { urlImage } from '../../utils/common';
import BreadcrumbsPath from "../../components/Breadcrumbs";

function NewsDetail(props: {newsId: number}) {
    const { t, i18n } = useTranslation();
    const params = useParams();

    const [newsItem, setNewsItem] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true);
        window.scrollTo(0, 0);
        setTimeout(() => {
            const getData = (domain: any) => {
                axios.get(
                    `${domain}/news/${params.slugTitle}`,
                    {headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                      }})
                .then(res => {
                    setNewsItem(res.data)
                }).finally(() => setLoading(false)
                )
            }
            getData(process.env.REACT_APP_API_DOMAIN)
        }, 500);
    }, [params])

    return (
        <LayoutPage>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BreadcrumbsPath pageName={newsItem && newsItem[`title_${i18n.language}`]} />
                </Grid>
                <Hidden smDown>
                    <Grid item md={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
                {loading && (
                    <Grid justifyContent="center" item md={9} sm={9} xs={12} sx={{clear: 'both'}}>
                        <CircularProgress color="success" />
                    </Grid>
                )}
                {!loading && (
                    <Grid item md={9} xs={12} container>
                        {!newsItem?.id && (
                            <Grid item xs={12}>
                                <Alert severity="info">URL không hợp lệ, vui lòng liên hệ với quản trị viên trang web!</Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <div style={{fontSize: 30, textAlign: 'left'}} dangerouslySetInnerHTML={{ __html: newsItem && newsItem[`title_${i18n.language}`] }}></div>
                        </Grid>
                        <Grid item xs={12}>
                            <CardMedia
                                component="div"
                                sx={{
                                // 16:9
                                pt: '56.25%',
                                }}
                                image={urlImage(newsItem.img)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <div dangerouslySetInnerHTML={{ __html: newsItem && newsItem[`summary_${i18n.language}`] }}></div>
                        </Grid>
                        <Grid item xs={12} sx={{textAlign: 'left'}}>
                            <div dangerouslySetInnerHTML={{ __html: newsItem && newsItem[`description_${i18n.language}`] }}></div>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                            <NewsRelated  news_id={newsItem.id} />
                        </Grid>
                    </Grid>
                )}
                <Hidden smUp>
                    <Grid item md={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
            </Grid>
        </LayoutPage>
    );
}

export default NewsDetail;