import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {
  Avatar,
  Button,
  CssBaseline,
  FormControlLabel,
  TextField,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  Alert,
  CircularProgress,
  AlertTitle,
} from '@mui/material';

import axios from "axios";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
export default function SignUp() {
  const { t, i18n } = useTranslation();
  const [msgError, setMsgError] = React.useState<string>('')
  const [createUserSuccess, setCreateUserSuccess] = React.useState<string>('')
  const [saving, setSaving] = React.useState<boolean>(false)

  const navigate = useNavigate();
  const handleSubmit = async(event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSaving(true);
    const data = new FormData(event.currentTarget);
    setMsgError("")
    const payload = {
      username: data.get('username'),
      first_name: data.get('firstName'),
      last_name: data.get('lastName'),
      email: data.get('email'),
      phone: data.get('phone'),
      address: data.get('address'),
      password: data.get('password'),
      password2: data.get('confirm_password'),
    }

    axios.post(`${process.env.REACT_APP_API_DOMAIN}/register`, payload)
      .then(res => {
        setCreateUserSuccess(res.data.username);
      })
      .catch((error) => {
        setMsgError(error.response.data);
      })
      .finally(() => {
        setSaving(false);
      });

  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t('signup')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
            {!!createUserSuccess ? (
              <React.Fragment>
                <Alert variant="filled" severity="success" sx={{ textAlign: 'justify', mb: 2 }}>
                  <AlertTitle>Chào {createUserSuccess},</AlertTitle>
                  Bạn đã tạo tài khoản thành công! Để tiếp tục sử dụng, hãy <Button sx={{ color:"white" }} onClick={
                        () => {
                          navigate(`/account/signin`)
                        }}><strong>{t('login')}</strong></Button> bằng tên người dùng và mật khẩu của mình tại [địa chỉ trang web].
                  Cảm ơn bạn đã đăng ký. Chúng tôi hy vọng bạn thích sử dụng dịch vụ của chúng tôi!
                </Alert>
                <Button
                  variant='contained'
                  onClick={
                      () => {
                        navigate(`/account/signin`)
                      }}
                    ><strong>{t('login')}</strong>
                </Button>
              </React.Fragment>
            ) :
            (<React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="username"
                    label="username"
                    name="username"
                    autoComplete="username"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="phone"
                    label="Phone number"
                    name="phone"
                    autoComplete="phone"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="address"
                    label="address"
                    name="address"
                    autoComplete="address"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirm_password"
                    label="confirm Password"
                    type="password"
                    id="confirm_password"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Checkbox required value="allowExtraEmails" color="primary" />}
                    label="I want to receive inspiration, marketing promotions and updates via email."
                  />
                </Grid>
              </Grid>
              {msgError && <Alert severity="error">{msgError}</Alert>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={saving}
              >
                Sign Up
                {saving && (
                      <CircularProgress
                          size={30}
                          sx={{
                              m: 2,
                              position: 'absolute',
                          }}
                      />
                  )}
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link variant="body2"
                    onClick={
                      () => {
                        navigate(`/account/signin`)
                      }}
                    >
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </React.Fragment>
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}