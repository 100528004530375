import { Container } from "@mui/material";
import React, {useState, useEffect} from "react";
import Header from './headerPage';
import Footer from './footerpage';
import { primary } from "../utils/colors";
import _ from "lodash";
import axios from "axios";

function LayoutPage(props: any) {
  const { children } = props;
  const [company, setCompany] = useState();
  const [productCategorys, setProductCategorys] = useState<any[]>([])

  useEffect(() => {
      if (_.isEmpty(company)) {
        axios.get(
            `/company`,
            {headers: {
              "Access-Control-Allow-Origin": "*"
            }}
        ).then(res => { 
          setCompany(res.data);
        })
        .catch((error) => {
            console.log('error', error)
        })

      }
      if (_.isEmpty(productCategorys)) {
        axios.get(
          `/productCat`,
          {headers: {
            "Access-Control-Allow-Origin": "*"
          }}
          
        ).then(res => {
          setProductCategorys(res.data);
        })
        .catch((error) => {
            console.log('error', error)
        })
      }
  }, [])
  
  return (
    <React.Fragment>
      <Header company={company} productCategorys={productCategorys}/>
      <Container maxWidth={'xl'} sx={{minHeight: '65vh', mb: 4}}>
        {children}
      </Container>
      <Container maxWidth={false} sx={{backgroundColor: primary, color: 'white'}} disableGutters>
        <Footer company={company} />
      </Container>
    </React.Fragment>
  );
}
export default LayoutPage;