import { Typography, Breadcrumbs } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function BreadcrumbsPath(props: any) {

    const { t } = useTranslation();

    return (
        <Breadcrumbs aria-label="breadcrumb">
            <Link to="/">
                {t('home')}
            </Link>
            <Typography color="text.primary">{props.pageName}</Typography>
        </Breadcrumbs>
    );
}
export default BreadcrumbsPath