import React, { createContext } from 'react';
// import logo from './logo.svg';
import './App.css';
import routers from './routers';
import { useLocation, useRoutes } from 'react-router-dom';

export const GlobalContext = createContext({})

function App() {
  const routing = useRoutes(routers);
  const location = useLocation();

  return (
    <div className="App">
      <GlobalContext.Provider value={{carts: [], user: {}}}>
          {routing}
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
