import {
    Pagination,
    Grid,
    Hidden,
    Alert,
    Button,
    Typography,
    CircularProgress
} from '@mui/material';
import LayoutPage from '../../components/layoutPage';
import ProductItem from '../../components/productItem';
import React, {useEffect, useState} from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import axios from "axios";
import { useTranslation } from 'react-i18next';
import BreadcrumbsPath from '../../components/Breadcrumbs';
import SidebarsProduct from '../../components/sidebarsProduct';

function Products() {
    const { t } = useTranslation();
    const params = useParams();
    const location = useLocation();
    const [searchParams, setSearchParams] = useState("")
    const [products, setProducts] = useState([])
    const [loading, setLoading] = useState<boolean>(false)

    const [pagination, setPagination] = useState<any>({
        totalCount: 0,
        next: "",
        previous: "",
        page: 1
    })
    useEffect(() => {
        setLoading(true);
        const payload: any = {page : pagination.page}
        // check if input params is product category
        if (location.search) {
            const product_name = new URLSearchParams(location.search).get('product_name') || "";
            setSearchParams(product_name);
            if (product_name)
                payload.search_name = product_name
        }

        
        
        window.scrollTo(0, 0);
        setTimeout(() => {
            const getData = (domain: any) => {
                let url = `${domain}/`
                let productCategory = ''
                if (params.hasOwnProperty('categoryId') && params.categoryId)
                    productCategory = params.categoryId

                if (!productCategory)
                    url += 'products/'
                else {
                    url +=`productCat/${productCategory}`
                }
                axios.get(url, {
                    params: payload,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                      }})
                .then(res => {
                    if (typeof res.data === "object"){
                        setPagination(
                            {   
                                ...pagination,
                                totalCount: res.data.count,
                                next: res.data.next,
                                previous: res.data.previous,
                                page: pagination.page || 1
                            })
                        setProducts(res.data.results);
                    } else {
                        setProducts(res.data);
                    }
                })
                .finally(() => setLoading(false))
            }

            getData(process.env.REACT_APP_API_DOMAIN)
        }, 250);
    }, [pagination?.page, params.page, params.categoryId, location.search])
    return (
        <LayoutPage>
            <Grid container spacing={2} p={2}>
                <Grid item sm={3} xs={12}>
                    <BreadcrumbsPath pageName={`${params.categoryId ? params.categoryId : t('products')} ${searchParams ? '/ Tìm kiếm [' : ''} ${searchParams} ${searchParams ? ']' : ''}`}/>
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Typography variant='body1' sx={{textAlign: 'left'}}>Có {pagination.totalCount} sản phẩm</Typography>
                </Grid>
                <Hidden smDown>
                    <Grid item md={3} sm={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
                <Grid item sm={9} xs={12} container spacing={1}>
                    {!loading && products.length === 0 && (
                        <Grid item xs={12}>
                            <Alert
                                severity="info"
                                action={
                                    <Button color="primary" variant="contained" size="small">
                                        <Link style={{color: 'white'}} to="/">
                                            {t('home')}
                                        </Link>
                                    </Button>
                                }
                            >
                                Rất tiếc, Funico.com.vn không tìm thấy kết sản phẩm nào.
                            </Alert>
                        </Grid>
                    )}
                    {loading && (
                        <Grid justifyContent="center" item md={9} sm={9} xs={12} sx={{clear: 'both'}}>
                            <CircularProgress color="success" />
                        </Grid>
                    )}
                    {products.length > 0 && products.map(product => (
                        <Grid item xs={6} sm={4} md={3}>
                            <ProductItem props={{'product': product}}/>
                        </Grid>
                    ))}
                    {pagination.totalCount > 0 && (
                        <Grid xs={12} item>
                            <Pagination
                                count={Math.ceil(pagination.totalCount / 12)}
                                color="primary"
                                page={pagination.page}
                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                    setPagination(
                                        {   
                                            ...pagination,
                                            page: value
                                        })
                                }}
                                />
                        </Grid>
                    )}
                </Grid>
                <Hidden smUp>
                    <Grid item md={3} sm={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
            </Grid>
        </LayoutPage>
    );
}
export default Products