import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      "home": "Home",
      "introduce": "Introduce",
      "products": "Products",
      "news": "News",
      "contact": "Contact",
      "product_catalogue": "Product Catalogue",
      "payment_instructions": "Payment instructions",
      "account": "Account",
      "new_product": "New product",
      "best_seller": "Best seller",
      "on_sale": "On sale",
      "describe": "Describe",
      "similar_product": "Similar product",
      "buy_now": "Buy now",
      "detail": "Detail",
      "cart": "Cart",
      "payment": "Payment",
      "sign_in": "Sign in",
      "forgot_password": "Forgot password?",
      "dont_have_account": "Don't have an account? Sign Up",
      "logout": "Logout",
      "no_product_in_cart": "There are currently no products in the cart.",
      "place_an_order": "Place an order",
      "name_product": "Name product",
      "delete_product": "Delete product",
      "number_of_product": "Number of product",
      "total_amount": "Total amount",
      "login": "Login",
      "read_more": "Continue reading...",
      "shipping_method": "Shipping method",
      "security_information": "Security information",
      "view_all": "View all",
      "information": "Information",
      "search_product": "Search product",
      "store": "Store",
      "about_us": "About us",
      "your_password_has_been_successfully_reset": "Your password has been successfully reset",
      "your_password_has_been_successfully_reset_content": "Please check your email to receive your new password. Once you have received your new password, you can use it to log in to your account.",
      "forgot_password_noti": " Forgot your password? Please enter your username or email address. You will receive a link to create a new password via email.",
      "btn_reset_password": "Reset password",
      "order_success": "Your order has been placed successfully. We will contact you as soon as possible.",
      "delivery_information": "Delivery information",
      "order": "Order",
      "go_back_to_the_store": "Go back to the store",
      "purchase": "Purchase",
      "add_to_cart": "Add to cart"
    }
  },
  vi: {
    translation: {
      "home": "Trang chủ",
      "introduce": "Giới thiệu",
      "products": "Sản phẩm",
      "news": "Tin tức",
      "contact": "Liên hệ",
      "product_catalogue": "Danh mục sản phẩm",
      "payment_instructions": "Hướng dẫn thanh toán",
      "account": "Tài khoản",
      "new_product": "Sản phẩm mới",
      "best_seller": "Bán chạy",
      "on_sale": "Đang khuyến mãi",
      "describe": "Mô tả",
      "similar_product": "Sản phẩm tương tự",
      "buy_now": "Mua ngay",
      "detail": "Chi tiết",
      "cart": "Giỏ hàng",
      "payment": "Thanh toán",
      "sign_in": "Đăng nhập",
      "forgot_password": "Quên mật khẩu?",
      "dont_have_account": "Chưa có tài khoản? Đăng ký",
      "logout": "Đăng xuất",
      "no_product_in_cart": "Hiện không có sản phẩm nào trong giỏ hàng.",
      "place_an_order": "Đặt hàng",
      "name_product": "Tên sản phẩm",
      "delete_product": "Xóa sản phẩm",
      "number_of_product": "Số lượng",
      "total_amount": "Thành tiền",
      "login": "Đăng nhập",
      "read_more": "Chi tiết...",
      "shipping_method": "Chính sách giao hàng",
      "security_information": "Bảo mật thông tin",
      "view_all": "Xem tất cả",
      "information": "Thông tin",
      "search_product": "Tìm kiếm sản phẩm",
      "store": "Cửa hàng",
      "about_us": "Funico Việt Nam",
      "your_password_has_been_successfully_reset": "Mật khẩu của bạn đã được đặt lại thành công.",
      "your_password_has_been_successfully_reset_content": "Vui lòng kiểm tra email của bạn để nhận mật khẩu mới của bạn. Sau khi bạn đã nhận được mật khẩu mới, bạn có thể sử dụng nó để đăng nhập vào tài khoản của mình.",
      "forgot_password_noti": "Quên mật khẩu? Vui lòng nhập tên đăng nhập hoặc địa chỉ email. Bạn sẽ nhận được một liên kết tạo mật khẩu mới qua email.",
      "btn_reset_password": "Đặt lại mật khẩu",
      "order_success": "Đơn hàng tạo thành công, chúng tôi sẽ liên hệ với bạn trong thời gian sớm nhất.",
      "delivery_information": "Thông tin giao hàng",
      "order": "Đặt hàng",
      "go_back_to_the_store": "Quay lại gian hàng",
      "purchase": "Đơn hàng",
      "add_to_cart": "Thêm vào giỏ hàng"
    }
  },
  de: {
    translation: {
      "home": "Startseite",
      "introduce": "Vorstellen",
      "products": "Produkte",
      "news": "Nachricht",
      "contact": "Kontakt",
      "product_catalogue": "Produktkatalog",
      "payment_instructions": "Zahlungsleitfaden",
      "account": "Konto",
      "new_product": "Neues Produkt",
      "best_seller": "Bestseller",
      "on_sale": "Im angebot",
      "describe": "describe",
      "similar_product": "Ähnliches Produkt",
      "buy_now": "Kaufe jetzt",
      "detail": "Detail",
      "cart": "Cart",
      "payment": "Nachlassen",
      "sign_in": "Anmelden",
      "forgot_password": "Passwort vergessen?",
      "dont_have_account": "Sie haben noch kein Konto? Anmelden",
      "logout": "Logout",
      "no_product_in_cart": "Es befinden sich derzeit keine Produkte im Warenkorb.",
      "place_an_order": "Bestellung aufgeben",
      "name_product": "Produkt benennen",
      "delete_product": "Delete product",
      "number_of_product": "Number of product",
      "total_amount": "Total amount",
      "login": "Anmelden",
      "read_more": "Read more...",
      "shipping_method": "Versandmethode",
      "security_information": "Sicherheitsinformationen",
      "view_all": "Alles anzeigen",
      "information": "Information",
      "search_product": "Produkt suchen",
      "store": "Abspeichern",
      "about_us": "Über uns",
      "your_password_has_been_successfully_reset": "Ihr Passwort wurde erfolgreich zurückgesetzt.",
      "your_password_has_been_successfully_reset_content": "Bitte überprüfen Sie Ihre E-Mail, um Ihr neues Passwort zu erhalten. Sobald Sie Ihr neues Passwort erhalten haben, können Sie es verwenden, um sich in Ihr Konto einzuloggen.",
      "forgot_password_noti": "Haben Sie Ihr Passwort vergessen? Bitte geben Sie Ihren Benutzernamen oder Ihre E-Mail-Adresse ein. Sie erhalten einen Link zum Erstellen eines neuen Passworts per E-Mail.",
      "btn_reset_password": "Passwort ändern",
      "order_success": "Ihre Bestellung wurde erfolgreich aufgegeben. Wir werden Sie so bald wie möglich kontaktieren.",
      "delivery_information": "Lieferinformationen",
      "order": "Bestellung",
      "go_back_to_the_store": "Kehren Sie zum Geschäft zurück",
      "purchase": "Bestellung",
      "add_to_cart": "Zum Warenkorb hinzufügen"
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "vi", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;