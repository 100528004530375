import * as React from 'react';
import {Grid, Tabs} from '@mui/material';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Orders from './orders';
import { primary } from '../../utils/colors';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function setTabIndex(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function AccountInfo() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [accountInfo, setAccountInfo] = React.useState<any>({})

  React.useEffect(() => {
    const userLogin = localStorage.user_info
    if (userLogin)
      setAccountInfo(JSON.parse(userLogin))
  }, [])

  return (
    <Box
      sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', minHeight: 224 }}
    >
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Account" {...setTabIndex(0)} />
        <Tab label="Orders" {...setTabIndex(1)} />
        {/* <Tab label="Change password" {...setTabIndex(2)} /> */}
      </Tabs>
      <TabPanel value={value} index={0}>
        {accountInfo && (
          <Box sx={{textAlign: 'left'}}>
            <Typography variant="subtitle1"  color={primary}>Username: {accountInfo.username}</Typography>
            <Typography variant="subtitle1"  color={primary}>Full name: {accountInfo.first_name} {accountInfo.last_name} </Typography>
            <Typography variant="subtitle1"  color={primary}>Email: {accountInfo.email}</Typography>
            <Typography variant="subtitle1"  color={primary}>Phone: {accountInfo.phone}</Typography>
            <Typography variant="subtitle1"  color={primary}>address: {accountInfo.address}</Typography>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Orders />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
        Change password
      </TabPanel> */}
    </Box>
  );
}