import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Grid, IconButton, Typography, CardMedia, Divider, Badge } from '@mui/material';
import { useTranslation, withTranslation } from 'react-i18next';
import DeleteIcon from '@mui/icons-material/Delete';
import { addProductToCart, deleteProductFromCart, formatPrice, urlImage } from '../../utils/common';
import AddIcon from '@mui/icons-material/Add';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';


export default withTranslation() (function Cart(props: any) {
  const { t, i18n } = useTranslation();
  const {carts} = props;

  const [totalAmount, setTotalAmount] = React.useState<any>(0)

  React.useEffect(() => {
      let total_amount = 0;
      for (const item of carts) {
        total_amount += ((item.number_of_product_in_cart || item?.num_of_product) * item.price)
      }
      setTotalAmount(total_amount)
  }, [carts])

  return (
    <React.Fragment>
      {carts.length === 0 && (
        <React.Fragment>
          <Divider />
          <Box sx={{ textAlign: 'center', mt: 3, color: "blue" }}>
            <RemoveShoppingCartIcon />
            <Typography sx={{fontStyle: 'italic', mt: 3, mb:3 }} variant='body1' color="blue">
              {t('no_product_in_cart')}
            </Typography>
          </Box>
        </React.Fragment>
      )}
      {carts.length > 0 && (
        <Grid container spacing={1}>
            {carts.map((item:any) => (
              <Grid item xs={12} container spacing={1}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={3}>
                  {props.isCheckout
                    ? (<Badge badgeContent={item?.number_of_product_in_cart || item?.num_of_product} color="primary">
                        <CardMedia
                          component="img"
                          sx={{borderRadius: 1 }}
                          image={urlImage(item.img)}
                        />
                        </Badge>
                    ) : <CardMedia
                          component="img"
                          sx={{borderRadius: 1 }}
                          image={urlImage(item.img)}
                        />
                  }
                </Grid>
                <Grid item xs={9} sx={{textAlign: 'start'}}>
                  <Typography variant='body2'>{t('name_product')}:</Typography>
                  <Typography variant='body1' color="primary">{item[`name_${i18n.language}`]}</Typography>
                  <Typography variant='body1' color={'red'}>{formatPrice(item.price)}</Typography>
                  {!props.isCheckout && (
                    <Box display={'flex'}>
                      <Box sx={{width: '88%', alignItems: 'center'}} display={'flex'}>
                        <Typography variant='body2'>{t('number_of_product')}:</Typography>
                        <IconButton
                          size="small"
                          onClick={() => {
                            deleteProductFromCart(item.id, true);
                            props.setReload();
                          }}
                          >
                          <HorizontalRuleIcon />
                        </IconButton>
                        <Typography> {item?.number_of_product_in_cart} </Typography>
                        <IconButton
                          size="small"
                          onClick={() => {
                            addProductToCart(item);
                            props.setReload();
                          }}>
                          <AddIcon />
                        </IconButton>
                        </Box>
                        <Box sx={{width: '12%'}}>
                          <IconButton
                            color="warning"
                            aria-label="delete"
                            onClick={() => {
                              deleteProductFromCart(item.id);
                              props.setReload();
                            }}
                            >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                </Grid>
              </Grid>
            ))}
        </Grid>
      )}
      <Divider />
      <Box sx={{textAlign: 'right'}}>
        <Typography variant='h5'>{t('total_amount')}: {formatPrice(totalAmount)}</Typography>
      </Box>
    </React.Fragment>
  );
})
