import * as React from 'react';

import {
  Button,
  Avatar,
  Link,
  TextField,
  Box,
  Alert,
  CssBaseline,
  Typography,
  Container,
  AlertTitle,
  CircularProgress
} from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LayoutPage from '../../../components/layoutPage';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function ForgetPassword(props: any) {
  const { t, i18n } = useTranslation();

  const [msgError, setMsgError] = React.useState<string>('')

  const [resetPasswordStatus, setResetPasswordStatus] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    setResetPasswordStatus(false);
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    setMsgError("")
    setTimeout(() => {
      const getData = (domain: any) => {
        axios.get(
          `${domain}/register`,
          {
            params: {email: data.get('email')},
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        
        )
        .then(res => {
          setResetPasswordStatus(true);
        })
        .catch((error) => {
          setMsgError(error.response.data)
        })
        .finally(() => setLoading(false))
      }

      getData(process.env.REACT_APP_API_DOMAIN)
    }, 500);
  };
  const navigate = useNavigate();

  return (
    <LayoutPage>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="sm">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            {resetPasswordStatus ? (
              <Box sx={{textAlign: 'start'}}>
                <Alert
                  severity="success"
                >
                  <AlertTitle>{t('your_password_has_been_successfully_reset')}</AlertTitle>
                  {t('your_password_has_been_successfully_reset_content')}
                </Alert>
                <Button sx={{mt: 3}} color="primary" variant="contained" size="small">
                  <Link
                    sx={{color: 'white'}}
                    onClick={
                        () => {
                          navigate(`/`)
                        }}
                    >
                      <strong>{t('home')}</strong>
                  </Link>
                </Button>
              </Box>
            ) : (
              <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <Typography variant='body1' sx={{textAlign: 'start'}}>{t('forgot_password_noti')}</Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                {/* <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Remember me"
                /> */}
                {msgError && <Alert severity="error">{msgError}</Alert>}
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                   {loading && (
                      <CircularProgress
                          size={30}
                          sx={{
                              m: 2,
                              position: 'absolute',
                          }}
                      />
                  )}
                  {t('btn_reset_password')}
                </Button>
                <Box sx={{textAlign: 'start'}}>
                    <Link
                      onClick={
                          () => {
                            navigate(`/account/signin`)
                          }}
                        ><strong>{t('login')}</strong>
                    </Link>
                    <Link variant="body2"
                      onClick={
                        () => {
                          navigate(`/account/signup`)
                        }}
                        sx={{float: 'right'}}
                      >
                      {t('dont_have_account')}
                    </Link>
                  </Box>
              </Box>
            )}
          </Box>
        </Container>
      </ThemeProvider>
    </LayoutPage>
  );
}