import { CssBaseline, Typography } from '@mui/material';
import LayoutPage from '../../components/layoutPage';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import SignIn from './SignIn';
import { isAuthenticated } from '../../utils/common';
import AccountInfo from './Account';
import SignUp from './SignUp';
import BreadcrumbsPath from '../../components/Breadcrumbs';

function Account(props: any) {
    const [typePage, setTypePage] = useState(props.type);
    
    useEffect(() => {
        setTypePage(props.type)
    }, [props.type])

    const { t, i18n } = useTranslation();

    const [page, setPage] = useState<any>()
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)
    const [reload, setReload] = useState<boolean>(false)

    useEffect(() => { 
        setIsLoggedIn(isAuthenticated())
    }, [reload])

    return (
        <LayoutPage>
            <BreadcrumbsPath pageName='Trang account' />
            <Typography variant='h5'>
                {page && page[`title_${i18n.language}`]}
            </Typography>
            {typePage != 'signup' && (isLoggedIn ? <AccountInfo /> : <SignIn setReload={() => setReload(!reload)} />)}
            {typePage === 'signup' && <SignUp />}
            <div dangerouslySetInnerHTML={{ __html: page && page[`description_${i18n.language}`] }}></div>
        </LayoutPage>
    );
}
export default Account