import {
    Box,
    Divider,
    Grid,
    Tabs,
    Tab,
    Typography
} from "@mui/material";
import { useTranslation } from 'react-i18next';
import React, {useEffect, useState} from "react";
import axios from "axios";
import NewItem from "../../components/newItem";
import { Link, useLocation } from 'react-router-dom';


function NewsRelated (props: any) {
    const { t, i18n } = useTranslation();
    const [news, setNews] = useState([]);
    const [value, setValue] = React.useState(0);
    const location = useLocation();

    useEffect(() => {
        const getData = (domain: any) => {
            let url = `${domain}/news`;
            let inputParams:any = {limit: location.pathname === '/' ? 6 : 4}
            if (props.news_id) {
                inputParams.excludeId = props.news_id;
            }

            axios.get(url,
                {
                    params: inputParams,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                      }})
            .then(res => {
                setNews(res.data)
            })
        }

        getData(process.env.REACT_APP_API_DOMAIN)
        
    }, [])

    function TabPanel(props: any) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && (
              <Box sx={{ pt: 1, pb: 1 }}>
                <Typography>{children}</Typography>
              </Box>
            )}
          </div>
        );
    }

    return (
        <Box>
            <Tabs centered value={value} onChange={() => {}} aria-label={'News related'}>
                <Tab
                    label={
                        <Typography sx={{fontWeight: 500}}>
                            News related
                        </Typography>
                    }
                />
            </Tabs>
            <Box>
                <Box sx={{float: 'right'}}>
                    <Link to={`/news`}>
                        {t('view_all')}
                    </Link>
                </Box>
                <Divider />
            </Box>
            <TabPanel value={value} index={0} p={0}>
                <Grid container spacing={1}>
                    {news.length > 0 && news.map(item => (
                        <Grid item md={location.pathname === '/' ? 2 : 3} sm={location.pathname === '/' ? 4 : 3} xs={6}>
                            <NewItem newsItem={item} />
                        </Grid>
                    ))}
                </Grid>
            </TabPanel>
        </Box>
    );
}

export default NewsRelated