import {
    Pagination,
    Grid,
    Hidden,
    Alert,
    Button,
    Typography,
    CircularProgress
} from '@mui/material';
import LayoutPage from '../../components/layoutPage';
import NewsItem from './newsListItem';
import React, {useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useParams, Link } from 'react-router-dom';
import BreadcrumbsPath from '../../components/Breadcrumbs';
import SidebarsProduct from '../../components/sidebarsProduct';

function News() {
    const params = useParams();
    const { t } = useTranslation();
    const [news, setNews] = useState<any[]>([])
    const [pagination, setPagination] = useState<any>({
        totalCount: 0,
        next: "",
        previous: "",
        page: 1
    })

    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true);

        setTimeout(() => {
            window.scrollTo(0, 0);
            const getData = (domain: any) => {
                axios.get(
                    `${domain}/news`,
                    {
                        params: {page : pagination.page || 1},
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                          }})
                .then((res: any) => {
                    if (typeof res.data === "object"){
                        setPagination(
                            {   
                                ...pagination,
                                totalCount: res.data.count,
                                next: res.data.next,
                                previous: res.data.previous,
                                page: pagination.page || 1
                            })
                        setNews(res.data.results)
                    } else {
                        setNews(res.data)
                    }
                })
                .finally(() => setLoading(false))
            }

            getData(process.env.REACT_APP_API_DOMAIN)
        }, 300);
    }, [pagination.page, params.page])

    return (
        <LayoutPage>
            <Grid container spacing={2} p={2}>
                <Grid item sm={3} xs={12}>
                    <BreadcrumbsPath pageName={t('news')} />
                </Grid>
                <Grid item sm={9} xs={12}>
                    <Typography variant='body1' sx={{textAlign: 'left'}}>Có {pagination.totalCount} bài viết</Typography>
                </Grid>
                <Hidden smDown>
                    <Grid item md={3} sm={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
                <Grid item xs={12} sm={9} md={9} container spacing={2}>
                    {!loading && news.length === 0 &&  (
                        <Grid item xs={12}>
                            <Alert
                                severity="info"
                                action={
                                    <Button color="primary" variant="contained" size="small">
                                        <Link to="/">
                                            {t('home')}
                                        </Link>
                                    </Button>
                                }
                            >
                                Hiện không có tin tuc nào. Hãy liên hệ với chúng tôi để được hỗ trợ sớm nhất.
                            </Alert>
                        </Grid>
                    )}
                    {loading && (
                        <Grid justifyContent="center" item md={9} sm={9} xs={12} sx={{clear: 'both'}}>
                            <CircularProgress color="success" />
                        </Grid>
                    )}
                    {
                        news && news.map((newsItem) => {
                            return (
                                <Grid item xs={12}>
                                    <NewsItem post={newsItem} />
                                </Grid>
                            );
                        })
                    }
                    {pagination.totalCount > 0 && (
                        <Grid xs={12} item>
                            <Pagination
                                count={Math.ceil(pagination.totalCount / 12)}
                                color="primary"
                                page={pagination.page}
                                onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                                    setPagination(
                                        {   
                                            ...pagination,
                                            page: value
                                        })
                                }}
                                />
                        </Grid>
                    )}
                </Grid>
                <Hidden smUp>
                    <Grid item md={3} sm={3} xs={12}>
                        <SidebarsProduct />
                    </Grid>
                </Hidden>
            </Grid>
        </LayoutPage>
    );
}
export default News