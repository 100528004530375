import { CardActionArea, Card, CardContent, Typography, CardMedia } from "@mui/material";
import { urlImage } from '../../utils/common';
import { useTranslation } from 'react-i18next';

interface FeaturedPostProps {
    post: any;
  }

function NewsItem (props: FeaturedPostProps) {
  const { t, i18n } = useTranslation();

    const { post } = props;

    return (
        <CardActionArea component="a" href={`/news/${post.news_slug}`} >
          <Card sx={{ display: 'flex' , p: 2, flexDirection: {xs: 'column', sm: 'row-reverse'} }}>
            <CardMedia
                component="img"
                sx={{ width: 160, float: { xs: 'none', sm: 'right' } }}
                image={urlImage(post.img)}
                alt={post.title_vi}
              />
            <CardContent sx={{ flex: 1, textAlign: 'left' }}>
              <Typography color="primary" variant="h6">
                {post[`title_${i18n.language}`]}
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
              >
                {post[`summary_${i18n.language}`].slice(0, 250)}...
              </Typography>
              <Typography variant="subtitle1" color="primary">
                {t('read_more')}
              </Typography>
            </CardContent>
          </Card>
        </CardActionArea>
      );
}

export default NewsItem