import {
    Typography,
    List,
    ListItem,
    ListItemText,
    Divider,
    ListItemAvatar,
    Avatar,
    Box,
    Pagination,
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import { formatPrice } from "../../utils/common";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useParams, Link } from 'react-router-dom';
import BreadcrumbsPath from '../../components/Breadcrumbs';

function Orders() {
    const { t, i18n } = useTranslation();
    const params = useParams();

    const [orders, setOrders] = useState<any[]>([])
    const [pagination, setPagination] = useState<any>({
        totalCount: 0,
        next: "",
        previous: "",
        page: 1
    })
    
    useEffect(() => {
              window.scrollTo(0, 0);
        const payload: any = {params: {page : pagination.page || 1}}
        const userToken = localStorage.user_token
        if (userToken) payload.headers =  {'Authorization': userToken}

        const getData = (domain: any) => {
          axios.get(`${domain}/shopping/`, payload).then(res => {
            if (typeof res.data === "object"){
              setPagination(
                  {   
                      ...pagination,
                      totalCount: res.data.count,
                      next: res.data.next,
                      previous: res.data.previous,
                      page: pagination.page || 1
                  })
                  setOrders(res.data.results)
              } else {
                  setOrders(res.data)
              }
          })
        }
        getData(process.env.REACT_APP_API_DOMAIN)
        
    }, [pagination.page])

    return (
        <React.Fragment>
          <BreadcrumbsPath pageName='Trang orders' />
          <Box sx={{pb: 2}}>
            <Typography sx={{float: 'left'}}>Tổng số đơn hàng: {pagination.totalCount} đơn</Typography>
          </Box>
          <List>
              {orders && orders.map(item => (
                  <React.Fragment>
                      <Divider />
                      <ListItem alignItems="flex-start">
                          <ListItemAvatar>
                              <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
                          </ListItemAvatar>
                          <ListItemText
                              primary={
                                <Link to={`/checkout/${item.id}`}>Mã đơn hàng: #{item.id}</Link>
                              }
                              secondary={
                                  <Box display='grid'>
                                    <Typography
                                      variant="body1"
                                      color="text.primary"
                                    >
                                      Tên khách hàng: {item.name_customer}
                                    </Typography>
                                    <Typography
                                      variant="body1"
                                      color="text.primary"
                                    >
                                      Số điện thoại: {item.phone_customer}
                                    </Typography>
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="text.primary"
                                    >
                                      Email: {item.email_customer}
                                    </Typography>
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="text.primary"
                                    >
                                      Địa chỉ: {item.addr_province} - {item.addr_distric} - {item.addr_ward} - {item.addr_customer}
                                    </Typography>
                                    <Typography 
                                      color="text.primary"
                                      >
                                      Note: {item.note}
                                    </Typography>
                                    <Typography 
                                      color="text.primary"
                                      >
                                      Ngày tạo: {item.created_at}
                                    </Typography>
                                    <Typography
                                      component="span"
                                      variant="body1"
                                      color="text.primary"
                                    >
                                      Tổng đơn hàng: {formatPrice(item.total_amount)}
                                    </Typography>
                                  </Box>
                              }
                          />
                      </ListItem>
                  </React.Fragment>
              ))}
              {pagination.totalCount > 0 && (
                <Pagination
                  count={Math.ceil(pagination.totalCount / 12)}
                  color="primary"
                  page={pagination.page}
                  onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                      setPagination(
                          {   
                              ...pagination,
                              page: value
                          })
                  }}
                  />
                )}
          </List>
        </React.Fragment>
    );
}
export default Orders