import { Card, Typography, CardMedia, CardContent, CardActions, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { addProductToCart, formatPrice, urlImage } from '../utils/common';
import { useTranslation } from 'react-i18next';

function ProductItem(props: any) {
    const product: any = props?.props?.product || {};
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    function handleClickToProductItem(id: number = 1) {
      navigate(`/products/${product.product_slug}`);
    }

    return (
          <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <CardMedia
              component="div"
              sx={{
                // 16:9
                pt: '56.25%',
              }}
              image={urlImage(product.img)}
              onClick={() => handleClickToProductItem()}
            />
            <CardContent sx={{ flexGrow: 1, padding: '4px' }}>
              <Typography
                gutterBottom
                variant="subtitle1"
                color={'primary'}
                onClick={() => handleClickToProductItem()}
                >
                {product && product[`name_${i18n.language}`]}
              </Typography>
              <Typography
                variant="subtitle2"
                onClick={() => handleClickToProductItem()}
                noWrap={true}
                >
                {product && product[`summary_${i18n.language}`]}
              </Typography>
              <Typography variant="h6" sx={{color: 'red'}}>
                { formatPrice(product.price) }
              </Typography>
            </CardContent>
            <CardActions sx={{alignSelf: 'center'}}>
              <Button onClick={() => {
                  addProductToCart(product);
                  document.getElementById('cart-icon')?.click()
                }}
                variant="contained"
                size="small"
                startIcon={<AddShoppingCartIcon />}
                >{t('buy_now')}</Button>
            </CardActions>
          </Card>
    );
}
export default ProductItem;
