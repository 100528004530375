import {
    MenuList,
    MenuItem,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Hidden
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CategoryIcon from '@mui/icons-material/Category';
import React from 'react';
import { primary } from '../utils/colors';

export default function ProductCategoryMenuItem(props: any) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const {productCategorys, isMobile} = props;

    function handleClickToProductCat(pro_cat_slug: string) {
      navigate(`/category_product/${pro_cat_slug}`);
    }

    const menuDesktop = (<MenuList>
            {productCategorys.map((item: any) => {
            return (
                    <MenuItem
                        onClick={() => handleClickToProductCat(item.pro_cat_slug)}
                        divider={true}
                        dense
                    >
                      <Hidden lgDown>
                        <Button
                          fullWidth={true}
                          size={'large'}
                          sx={{
                            placeContent: 'start',
                            justifyContent: 'space-between',
                            textWrap :'wrap',
                            textAlign: 'start'
                          }}
                          endIcon={<ArrowRightIcon />}
                          >
                            {item[`name_${i18n.language}`]}
                        </Button>
                      </Hidden>
                      <Hidden lgUp>
                        <Button
                          fullWidth={true}
                          size={'small'}
                          sx={{
                            placeContent: 'start',
                            justifyContent: 'space-between',
                            textWrap :'wrap',
                            textAlign: 'start'
                          }}
                          endIcon={<ArrowRightIcon />}
                          >
                            {item[`name_${i18n.language}`]}
                        </Button>
                      </Hidden>
                    </MenuItem>
                )
            })}
        </MenuList>
    );

    const menuMobile = (
        <List>
          {props.productCategorys.map((item: any, index: number) => (
            <ListItem key={item.id} disablePadding dense onClick={() => handleClickToProductCat(item.pro_cat_slug)}>
              <ListItemButton>
                <CategoryIcon color="primary" />
                <ListItemText sx={{ml: 2, color: primary, textTransform: 'capitalize' }} primary={item[`name_${i18n.language}`]} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
    )

    return isMobile ? menuMobile : menuDesktop;
}