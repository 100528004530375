import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import { Container, IconButton, Typography, Badge, Avatar } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CloseIcon from '@mui/icons-material/Close';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { blue } from '@mui/material/colors';
import Cart from './cart';

export default withTranslation() (function CartHeader() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [numberProductInCart, setNumberProductInCart] = React.useState(0);
  const [carts, setCarts] = React.useState([])

  const [state, setState] = React.useState({
    right: false,
  });

  const [reload, setReload] = React.useState(false)

  React.useEffect(() => {
      const cartsInStorage = localStorage.getItem("carts")
      const carts = cartsInStorage ? JSON.parse(cartsInStorage) : []
      setCarts(carts)
      setNumberProductInCart(carts.length)
  }, [state, reload])

  const toggleDrawer =
    (open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ right: open });
    };

  return (
    <React.Fragment>
        <Badge badgeContent={numberProductInCart || '0'} color="secondary">
          <Avatar variant="rounded" id="cart-icon" onClick={toggleDrawer(true)} sx={{ bgcolor: blue[900] }}>
              <ShoppingCartIcon />
          </Avatar>
        </Badge>
        <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        > 
          <Container  sx={{ width: {sm: 420, xs: 330} }}>
            <Box display={'flex'}>
                <Box sx={{mt: 1}}>
                  <IconButton onClick={toggleDrawer(false)}>
                      <CloseIcon />
                  </IconButton>
                </Box>
                <Box sx={{margin: '10px auto'}}>
                    <Typography variant='h5' sx={{fontWeight: 'bold'}}>
                      {t('cart')}
                    </Typography>
                    <Typography variant='body1'>
                    {numberProductInCart} {t('products')}
                    </Typography>
                </Box>
            </Box>
            <Cart carts={carts} setReload={() => setReload(!reload)} />
            <Box mt={5} sx={{textAlign: 'center'}}>
              <Button startIcon={<LocalGroceryStoreIcon />} disabled={carts.length === 0} onClick={() => navigate(`/checkout`)} variant='contained'>{t('place_an_order')}</Button>
            </Box>
          </Container>
        </SwipeableDrawer>
    </React.Fragment>
  );
})
