export function urlImage (url: string) {
    let urlImage = process.env.REACT_APP_API_DOMAIN;
    if (!!url)
        if (url.startsWith('/'))
            urlImage += url
        else
            urlImage += '/images/' +  url
    return urlImage
}

export function formatPrice(number: string) {
    return `${number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} đ`;
}

export function addProductToCart(product: any) {
    const carts = localStorage.carts ? JSON.parse(localStorage.carts) : []
    const productItem = carts.find( (item: any)=> item.id === product.id);
    if (!!productItem) {
        carts[carts.indexOf(productItem)].number_of_product_in_cart += 1;
    } else {
        carts.push({...product, number_of_product_in_cart : 1})
    }
    localStorage.setItem('carts', JSON.stringify(carts))
}

export function deleteProductFromCart(product_id: number, deleteOneItem: boolean = false) {
    const currentCarts = localStorage.carts ? JSON.parse(localStorage.carts) : []
    const productItem = currentCarts.find( (item: any)=> item.id === product_id);
    if (deleteOneItem && productItem.number_of_product_in_cart > 1) {
        currentCarts[currentCarts.indexOf(productItem)].number_of_product_in_cart -= 1;
    } else {
        currentCarts.splice(currentCarts.findIndex((item: any) => item.id === product_id), 1);
    }
    localStorage.setItem('carts', JSON.stringify(currentCarts))
}

export function isAuthenticated() {
    return !!localStorage.getItem('user_token');
}

export function logout() {
    localStorage.clear();
}