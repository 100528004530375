import React from 'react';
import HomePage from './pages/home';
import Products from './pages/products';
import News from './pages/news';
import Account from './pages/account';
import ForgetPassword from './pages/account/ForgetPassword'
import SinglePage from './pages/singlePage';
import NewsDetail from './pages/news/newsDetail';
import ProductDetail from './pages/products/productDetail';
import Checkout from './pages/checkout';

const routers = [
    {
        path: '',
        element: <HomePage />
    },
    // get all product
    {
        path: 'category_product',
        element: <Products />
    },
    // Get product by category slug
    {
        path: 'category_product/:categoryId',
        element: <Products />
    },
    // get product by id
    {
        path: 'products/:productId',
        element: <ProductDetail />
    },
    // get news list
    {
        path: 'news',
        element: <News />
    },
    // get news detail
    {
        path: 'news/:slugTitle',
        element: <NewsDetail />
    },
    {
        path: ':pageSlug',
        element: <SinglePage />
    },
    {
        path: 'account',
        element: <Account type='account' />
    },
    {
        path: 'account/signin',
        element: <Account type='signin' />
    },
    {
        path: 'account/signup',
        element: <Account type='signup' />
    },
    {
        path: 'checkout',
        element: <Checkout />
    },
    {
        path: 'checkout/:shoppingId',
        element: <Checkout />
    },
    {
        path: 'forgot-password',
        element: <ForgetPassword />
    }
]

export default routers;
