import {
    Typography,
    Grid,
    TextField,
    Box,
    Button,
    Alert
} from '@mui/material';
import LayoutPage from '../../components/layoutPage';
import React, {useState, useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cart from '../../components/cartHeader/cart';
import { useParams, Link } from 'react-router-dom';
import BreadcrumbsPath from '../../components/Breadcrumbs';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
    CircularProgress,
    Autocomplete
} from '@mui/material';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import _ from "lodash";
import { provinces } from "../../utils/provinces";


function Checkout() {
    const { t, i18n } = useTranslation();
    const params = useParams();
    const [carts, setCarts] = useState<any>([])

    const _defaultwards: any = provinces[0]['Districts'][0]['Wards']
    const [orderinfo, setOrderInfo] = useState<any>({
        'addr_province': {Name: provinces[0]['Name']},
        'addr_distric': {Name: provinces[0]['Districts'][0]['Name']},
        'addr_ward': {Name: _defaultwards ? _defaultwards[0]['Name'] : ''}
    
    })
    const [reload, setReload] = useState(false)

    // const [localDistricts, setLocalDistricts] = useState<any>({})

    const updateOrderinformations = (name: any , value: any) => {
        let params: any = {}
        if (!!value && ['addr_province', 'addr_distric', 'addr_ward'].includes(name) &&  typeof value === 'object') {
            // update information input select
            if (name === 'addr_province') {
                setDistricts(value['Districts'])
                params['addr_distric'] = value['Districts'][0]
                setWards(value['Districts'][0]['Wards'])
                if (value['Districts'][0]['Wards']) {
                    const _wards = value['Districts'][0]['Wards']
                    params['addr_ward'] = {Name: _wards[0]['Name']}
                }
            }
            // update information input select
            if (name === 'addr_distric') {
                params[name] = {'Name': value['Name']}
                setWards(value['Wards'])
                if (value['Wards']) {
                    const _wards = value['Wards']
                    params['addr_ward'] = _wards[0]
                }
            }
            if (name === 'addr_ward') {
                params[name] = value
            }
        } else {
            params[name] = value
        }
        setOrderInfo({...orderinfo, ...params})
    }

    const [shoppingInfo, setShoppingInfo] = useState<any>({});

    useEffect(() => {
        if (params.shoppingId) {
            const getData = (domain: any) => {
                const payload: any = {}
                const userToken = localStorage.user_token
                if (userToken) payload.headers =  {'Authorization': userToken}
                axios.get(
                    `${domain}/shopping/${params.shoppingId}`,
                    {
                        ...payload,
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded"
                        }    
                    })
                    .then(res => {
                        setShoppingInfo(res.data)
                    })
            }

            getData(process.env.REACT_APP_API_DOMAIN)
        }
    }, [params.shoppingId])

    useEffect(() => {
        const cartsLocalStorage = localStorage.carts
        !!cartsLocalStorage ? setCarts(JSON.parse(cartsLocalStorage)) : setCarts([])
    }, [reload])

    const navigate = useNavigate();

    const [saving, setSaving] = useState<boolean>(false);

    const submitOrder = (event: any) => {
        event.preventDefault();
        setSaving(true);
        // format orderinfo data
        const cartsInfo: any[] = []
        for (const cartItem of carts) {
            cartsInfo.push(
                {
                    productId: cartItem.id,
                    numberProductOrder: cartItem.number_of_product_in_cart,
                    price: cartItem.price
                }
            )
        }
        orderinfo.cartItem = cartsInfo
        const userLogin = localStorage.user_info
        if (userLogin)
            orderinfo.user_id = JSON.parse(userLogin).id
        axios.post(`${process.env.REACT_APP_API_DOMAIN}/shopping/`, {
            ...orderinfo,
            addr_province: orderinfo.addr_province.Name,
            addr_distric: orderinfo.addr_distric.Name,
            addr_ward: orderinfo.addr_ward.Name
        })
        .then(res => {
            const orderInfo = res.data
            orderInfo.order_items = _.clone(carts)
            setShoppingInfo(orderInfo)
            // navigate("/checkout/${res.data.id}");
        }).finally(() => {
            localStorage.removeItem('carts');
            setCarts([])
            setSaving(false);
        })
    }

    const [province , setProvince ] = useState<any[]>([])
    const [districts, setDistricts] = useState<any[]>([])
    const [wards , setWards ] = useState<any[]>([])

    useEffect(() => {
        if (province) {
            setProvince(provinces);
            setDistricts(provinces[0]['Districts']);
            setWards(provinces[0]['Districts'][0]['Wards']);
        }
    }, [])


    return (
        <LayoutPage>
            <Box component="form" onSubmit={submitOrder}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sx={{pb: 3, pt: 1}}>
                        <BreadcrumbsPath pageName={`Checkout page`} />
                    </Grid>
                    {carts.length === 0 && !saving && !params?.shoppingId && !_.isEmpty(orderinfo) && (
                        <Grid item xs={12}>
                            <Alert
                                severity="success"
                                action={
                                    <Button color="primary" variant="contained" size="small">
                                        <Link style={{color: 'white'}} to="/">
                                            {t('home')}
                                        </Link>
                                    </Button>
                                }
                            >
                                {t('order_success')}
                            </Alert>
                        </Grid>
                    )}
                    {!params?.shoppingId ? (
                        <Grid item container md={7} sm={7} xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='h5'>{t('delivery_information')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth={true}
                                    id="name_customer"
                                    label="Họ và tên"
                                    variant="outlined"
                                    name="name_customer"
                                    onChange={(e) => updateOrderinformations('name_customer', e.target.value)}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth={true}
                                    id="phone_customer"
                                    name="phone_customer"
                                    label="Số điện thoại"
                                    variant="outlined"
                                    onChange={(e) => updateOrderinformations('phone_customer', e.target.value)}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth={true}
                                    id="email_customer"
                                    name="email_customer"
                                    label="Email"
                                    variant="outlined"
                                    onChange={(e) => updateOrderinformations('email_customer', e.target.value)}
                                    />
                            </Grid>
                            <Grid item container xs={12} spacing={2}>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-addr_province"
                                        options={province}
                                        getOptionLabel={(option) => option?.Name || ""}
                                        onChange={(e, option) => updateOrderinformations('addr_province', option)}
                                        renderInput={
                                            (params: any) => <TextField
                                                {...params}
                                                required
                                                key="addr_province1"
                                                id="addr_province"
                                                name="addr_province"
                                                label="Tỉnh/Thành phố"
                                                />
                                        }
                                        renderOption={(props, option) => <li {...props}>{option?.Name || ""}</li>}
                                        />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={orderinfo.addr_distric}
                                        disablePortal
                                        id="combo-box-addr_distric"
                                        options={districts}
                                        getOptionLabel={(option) => option?.Name || ""}
                                        onChange={(e, option) => updateOrderinformations('addr_distric', option)}
                                        renderInput={
                                            (params: any) => <TextField
                                                {...params}
                                                required
                                                key="addr_distric1"
                                                id="addr_distric"
                                                name="addr_distric"
                                                label="Quận/Huyện"
                                                />
                                        }
                                        renderOption={(props, option) => <li {...props}>{option?.Name || ""}</li>}
                                        />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        value={orderinfo.addr_ward}
                                        disablePortal
                                        id="combo-box-addr_ward"
                                        options={wards}
                                        getOptionLabel={(option) => option?.Name || ""}
                                        onChange={(e, option) => updateOrderinformations('addr_ward', option)}
                                        renderInput={
                                            (params: any) => <TextField
                                                {...params}
                                                required
                                                key="addr_ward1"
                                                id="addr_ward"
                                                name="addr_ward"
                                                label="Phường/Xã"
                                                />
                                        }
                                        renderOption={(props, option) => <li {...props}>{option?.Name || ""}</li>}
                                        />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth={true}
                                    id="addr_customer"
                                    label="Địa chỉ"
                                    variant="outlined"
                                    onChange={(e) => updateOrderinformations('addr_customer', e.target.value)}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    id="note"
                                    name="note"
                                    label="Note"
                                    variant="outlined"
                                    onChange={(e) => updateOrderinformations('note', e.target.value)}
                                    />
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid item container md={7} sm={7} xs={12} sx={{textAlign: 'start'}}>
                            <Grid item xs={12}>
                                <Typography variant='h5'>Thông tin giao hàng</Typography>
                                <Typography variant='h6' color="primary">Number of order: #{shoppingInfo.id}</Typography>
                                <Typography variant='h6' color="primary">Name: {shoppingInfo.name_customer}</Typography>
                                <Typography variant='h6' color="primary">Email: {shoppingInfo.email_customer}</Typography>
                                <Typography variant='h6' color="primary">Phone: {shoppingInfo.phone_customer}</Typography>
                                <Typography variant='h6' color="primary">Address: {shoppingInfo.phone_customer}</Typography>
                                <Typography variant='h6' color="primary">Note: {shoppingInfo.addr_ward} - {shoppingInfo.addr_province} - {shoppingInfo.addr_distric}</Typography>
                                <Typography variant='h6' color="primary">Phuong thuc thanh toan: {shoppingInfo.payment_method}</Typography>
                                <Typography variant='h6' color="primary">Status: {shoppingInfo.shipping_method}</Typography>

                            </Grid>
                            <Grid item xs={12}></Grid>
                        </Grid>
                    )}
                    <Grid item container md={5} sm={5} xs={12} sx={{minHeight: '50vh', display: 'block'}}>
                        <Grid item xs={12}>
                            <Typography variant='h5'>{t('purchase')}({_.isEmpty(shoppingInfo) ? (carts?.length || 0) : (shoppingInfo?.order_items?.length || 0)} {t('product')})</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Cart carts={_.isEmpty(shoppingInfo) ? carts : (shoppingInfo?.order_items || [])} setReload={() => setReload(!reload)} isCheckout={true} />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                        <Grid item xs={6} sx={{textAlign: 'end'}}>
                            <Button startIcon={<ArrowBackIcon />} disabled={saving} color="secondary" variant="outlined" sx={{ m: 2 }} onClick={() => navigate(`/`)}>{t('go_back_to_the_store')}</Button>
                        </Grid>
                        <Grid item xs={6} sx={{textAlign: 'start'}}>
                            <Button endIcon={<LocalShippingIcon />} disabled={(carts.length === 0) || saving || !!params?.shoppingId}  type="submit" variant='contained' sx={{ m: 2 }}>
                                {t('order')}
                                {saving && (
                                    <CircularProgress
                                        size={30}
                                        sx={{
                                            m: 2,
                                            position: 'absolute',
                                        }}
                                    />
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </LayoutPage>
    );
}
export default Checkout